<template>
  <div class="grid-x grid-margin-x">
    <div class="cell medium-12 page__inner-content">
      <div class="grid-x grid-margin-x">
        <div class="cell medium-6">
          <card-item
            :title="$t('interface.general_colors')"
            icon="icon-interface-paint_roller"
          >
            <ab-color-picker
              :value.sync="settings.general_primary_color"
              :label="$t('interface.primary_color')"
              type="large"
            ></ab-color-picker>

            <ab-color-picker
              :value.sync="settings.general_secondary_color"
              :label="$t('interface.secondary_color')"
              type="large"
            ></ab-color-picker>
          </card-item>
        </div>
        <div class="cell medium-6">
          <card-item
            :title="$t('interface.menu_colors')"
            icon="icon-interface-paint_roller"
          >
            <ab-color-picker
              :value.sync="settings.menu_primary_color"
              :label="$t('interface.menu_top_color')"
              type="large"
            ></ab-color-picker>
            <ab-color-picker
              :value.sync="settings.menu_secondary_color"
              :label="$t('interface.menu_background_color')"
              type="large"
            ></ab-color-picker>
          </card-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InterfaceSettingsColors',
  props: ['content'],
  computed: {
    settings: {
      get () {
        return this.content
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
